import axios from '@/common/axios'
import qs from 'qs'

export function addLaboratoryHumidity (data) {
  return axios({
    method: 'post',
    url: '/quality/laboratoryHumidity/add',
    data: qs.stringify(data)
  })
}

export function deleteLaboratoryHumidity (id) {
  return axios({
    method: 'delete',
    url: '/quality/laboratoryHumidity/delete/' + id
  })
}

export function updateLaboratoryHumidity (data) {
  return axios({
    method: 'put',
    url: '/quality/laboratoryHumidity/update',
    data: qs.stringify(data)
  })
}

export function selectLaboratoryHumidityInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/laboratoryHumidity/info/' + id
  })
}

export function selectLaboratoryHumidityList (query) {
  return axios({
    method: 'get',
    url: '/quality/laboratoryHumidity/list',
    params: query
  })
}
