<template>
  <div id="laboratoryHumidity">
    <el-dialog
      :title="laboratoryHumidityFormTitle"
      width="1500px"
      :visible.sync="laboratoryHumidityDialogVisible"
      :close-on-click-modal="false"
      @close="laboratoryHumidityDialogClose"
    >
      <div id="pdfDom">
        <h1 style="text-align: center">
          实验室温湿度记录表
        </h1>
        <el-form
          ref="laboratoryHumidityFormRef"
          :model="laboratoryHumidityForm"
          :rules="laboratoryHumidityFormRules"
          label-position="right"
          label-width="100px"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="年度" prop="year">
                <el-input v-model="laboratoryHumidityForm.year" placeholder="请输入年度" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="月份" prop="month">
                <el-input v-model="laboratoryHumidityForm.month" placeholder="请输入月份" clearable />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <vxe-toolbar
              v-if="laboratoryHumidityFormTitle === '新增实验室温湿度记录表'
                || laboratoryHumidityFormTitle === '修改实验室温湿度记录表'"
            >
              <template #buttons>
                <vxe-button @click="insertRowEvent()">
                  新增
                </vxe-button>
              </template>
            </vxe-toolbar>
            <vxe-table
              ref="xTable"
              border
              resizable
              show-overflow
              keep-source
              :data="humidityDetailList"
              :edit-config="{trigger: 'click', mode: 'cell'}"
            >
              <vxe-table-column
                field="recordDate"
                title="日期"
                width="150"
                :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
              />
              <vxe-table-colgroup title="XY-J-091（阳性间）" header-align="center">
                <vxe-table-column
                  field="temperature1"
                  title="温度℃"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="humidity1"
                  title="湿度%"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
              </vxe-table-colgroup>
              <vxe-table-colgroup title="XY-J-022（无菌室）" header-align="center">
                <vxe-table-column
                  field="temperature2"
                  title="温度℃"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="humidity2"
                  title="湿度%"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
              </vxe-table-colgroup>
              <vxe-table-colgroup title="XY-J-072（实验室）" header-align="center">
                <vxe-table-column
                  field="temperature3"
                  title="温度℃"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="humidity3"
                  title="湿度%"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
              </vxe-table-colgroup>
              <vxe-table-colgroup title="XY-J-082（微生物）" header-align="center">
                <vxe-table-column
                  field="temperature4"
                  title="温度℃"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="humidity4"
                  title="湿度%"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
              </vxe-table-colgroup>
              <vxe-table-colgroup title="XY-J-083（留样室）" header-align="center">
                <vxe-table-column
                  field="temperature5"
                  title="温度℃"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="humidity5"
                  title="湿度%"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
              </vxe-table-colgroup>
              <vxe-table-column field="result" title="监测结果" width="150">
                <template v-slot="{row,column}">
                  <vxe-radio-group v-model="row[column.property]">
                    <vxe-radio :label="1" content="合格" />
                    <vxe-radio :label="2" content="不合格" />
                  </vxe-radio-group>
                </template>
              </vxe-table-column>
              <vxe-table-column
                v-if="laboratoryHumidityFormTitle === '新增实验室温湿度记录表'
                  || laboratoryHumidityFormTitle === '修改实验室温湿度记录表'"
                title="操作"
                width="100"
              >
                <template #default="{ row }">
                  <template>
                    <vxe-button @click="removeRowEvent(row)">
                      删除
                    </vxe-button>
                  </template>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="laboratoryHumidityDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="laboratoryHumidityFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="laboratoryHumidityFormTitle === '实验室温湿度记录表详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="月份">
        <el-input v-model="searchForm.month" placeholder="请输入月份" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="laboratoryHumidityPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="year" label="年度" />
      <el-table-column prop="month" label="月份" />
      <el-table-column prop="recorder" label="记录人" />
      <!-- <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recordDate">{{ scope.row.recordDate.substring(0, 10) }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="laboratoryHumidityPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addLaboratoryHumidity,
  deleteLaboratoryHumidity,
  updateLaboratoryHumidity,
  selectLaboratoryHumidityInfo,
  selectLaboratoryHumidityList
} from '@/api/quality/laboratoryHumidity'
import html2PDF from 'jspdf-html2canvas'
import { Loading } from 'element-ui'

export default {
  data () {
    return {
      laboratoryHumidityDialogVisible: false,
      laboratoryHumidityFormTitle: '',
      laboratoryHumidityForm: {
        id: '',
        year: '',
        month: '',
        humidityDetailJson: ''
      },
      laboratoryHumidityFormRules: {
        month: [{ required: true, message: '月份不能为空', trigger: ['blur', 'change']}]
      },
      laboratoryHumidityPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        month: ''
      },
      humidityDetailList: []
    }
  },
  created () {
    selectLaboratoryHumidityList(this.searchForm).then(res => {
      this.laboratoryHumidityPage = res
    })
  },
  methods: {
    laboratoryHumidityDialogClose () {
      this.$refs.laboratoryHumidityFormRef.resetFields()
      this.humidityDetailList = []
    },
    laboratoryHumidityFormSubmit () {
      if (this.laboratoryHumidityFormTitle === '实验室温湿度记录表详情') {
        this.laboratoryHumidityDialogVisible = false
        return
      }
      this.$refs.laboratoryHumidityFormRef.validate(async valid => {
        if (valid) {
          this.laboratoryHumidityForm.humidityDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.laboratoryHumidityFormTitle === '新增实验室温湿度记录表') {
            this.laboratoryHumidityForm.id = ''
            await addLaboratoryHumidity(this.laboratoryHumidityForm)
          } else if (this.laboratoryHumidityFormTitle === '修改实验室温湿度记录表') {
            await updateLaboratoryHumidity(this.laboratoryHumidityForm)
          }
          this.laboratoryHumidityPage = await selectLaboratoryHumidityList(this.searchForm)
          this.laboratoryHumidityDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.laboratoryHumidityFormTitle = '新增实验室温湿度记录表'
      this.laboratoryHumidityDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteLaboratoryHumidity(row.id)
        if (this.laboratoryHumidityPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.laboratoryHumidityPage = await selectLaboratoryHumidityList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.laboratoryHumidityFormTitle = '修改实验室温湿度记录表'
      this.laboratoryHumidityDialogVisible = true
      this.selectLaboratoryHumidityInfoById(row.id)
    },
    handleInfo (index, row) {
      this.laboratoryHumidityFormTitle = '实验室温湿度记录表详情'
      this.laboratoryHumidityDialogVisible = true
      this.selectLaboratoryHumidityInfoById(row.id)
    },
    selectLaboratoryHumidityInfoById (id) {
      selectLaboratoryHumidityInfo(id).then(res => {
        this.laboratoryHumidityForm.id = res.id
        this.laboratoryHumidityForm.year = res.year
        this.laboratoryHumidityForm.month = res.month
        this.humidityDetailList = res.humidityDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectLaboratoryHumidityList(this.searchForm).then(res => {
        this.laboratoryHumidityPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectLaboratoryHumidityList(this.searchForm).then(res => {
        this.laboratoryHumidityPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectLaboratoryHumidityList(this.searchForm).then(res => {
        this.laboratoryHumidityPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `实验室温湿度记录表${this.laboratoryHumidityForm.year + '-' + this.laboratoryHumidityForm.month}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
